import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import styled, { keyframes } from "styled-components";
import RadarChart from "./RadarChart";
import { addDummyData } from "../utils/addDummyData";
import NestedTable from "./NestedTable";
import TeamSimilarityMatrix from "./TeamSimilarityMatrix";
import { deleteAllResponses } from "../utils/deleteResponses";

const Container = styled.div`
    max-width: 1200px;
    margin: 30px auto;
    padding: 0 20px;

    @media (max-width: 768px) {
        padding: 0 15px;
        margin: 20px auto;
    }

    @media (max-width: 480px) {
        padding: 0 10px;
        margin: 15px auto;
    }
`;

const PageTitle = styled.h1`
    font-size: 32px;
    color: #1b1f21;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        height: 4px;
        background-color: #c7ea47;
        border-radius: 2px;
    }

    @media (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 30px;
    }

    @media (max-width: 480px) {
        font-size: 24px;
        margin-bottom: 20px;
    }
`;

const SectionTitle = styled.h2`
    font-size: 24px;
    color: #1b1f21;
    margin: 30px 0 20px;
    text-align: left;
    padding-left: 10px;
    border-left: 4px solid #c7ea47;

    @media (max-width: 768px) {
        font-size: 20px;
        margin: 25px 0 15px;
    }

    @media (max-width: 480px) {
        font-size: 24px;
        margin-bottom: 20px;
    }
`;

const Section = styled.section`
    background: white;
    border-radius: 12px;
    padding: 25px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        padding: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 480px) {
        padding: 15px;
        margin-bottom: 15px;
    }
`;

const LoadingMessage = styled.div`
    text-align: center;
    padding: 40px;
    font-size: 18px;
    color: #666;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
        padding: 30px;
        font-size: 16px;
    }

    @media (max-width: 480px) {
        padding: 20px;
        font-size: 14px;
    }
`;

// ✅ Blinking ellipsis animation
const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
`;

const Button = styled.button`
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #c7ea47;
    color: black;
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;

    &:hover {
        background-color: #B3d134;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const Ellipsis = styled.span`
    display: inline-block;
    animation: ${blink} 1.5s infinite;
`;

// ✅ White space divider for better separation
const Divider = styled.div`
    height: 20px;
    background-color: white;
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
    margin: 20px 0 40px;

    @media (max-width: 480px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const Results = ({ user }) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            console.log("Starting to fetch data in Results component");
            const querySnapshot = await getDocs(collection(db, "responses"));
            const documents = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            console.log("Results component - Number of documents:", documents.length);
            console.log("Results component - Documents:", documents);
            setDataLoaded(!querySnapshot.empty);
        };

        fetchData();
    }, []);

    const handleDeleteResponses = async () => {
        if (window.confirm('Are you sure you want to delete all responses? This action cannot be undone.')) {
            const success = await deleteAllResponses();
            if (success) {
                setDataLoaded(false);
                window.location.reload();
            }
        }
    };

    const isAdmin = user && (user.email === 'matt@stackpoint.com' || user.email === 'evan@stackpoint.com');

    return (
        <Container>
            <PageTitle>👀 Survey Results 👀</PageTitle>
            
            {/* Admin buttons temporarily disabled
            {isAdmin && (
                <ButtonContainer>
                    <Button onClick={() => addDummyData(setLoading)} disabled={loading}>
                        {loading ? (
                            <>Generating Dummy Data<Ellipsis>...</Ellipsis></>
                        ) : (
                            "Generate Dummy Data"
                        )}
                    </Button>
                    <Button 
                        onClick={handleDeleteResponses} 
                        style={{ backgroundColor: '#ff4444' }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#ff2222'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#ff4444'}
                    >
                        Delete All Responses
                    </Button>
                </ButtonContainer>
            )}
            */}

            {dataLoaded ? (
                <>
                    <Section>
                        <SectionTitle>🕸️ Team Overview</SectionTitle>
                        <RadarChart />
                    </Section>

                    <Section>
                        <SectionTitle>🤝 Team Similarity Analysis</SectionTitle>
                        <TeamSimilarityMatrix />
                    </Section>

                    <Section>
                        <SectionTitle>📊 Detailed Responses</SectionTitle>
                        <NestedTable />
                    </Section>

                
                </>
            ) : (
                <LoadingMessage>
                    <p>Loading survey data...</p>
                </LoadingMessage>
            )}
        </Container>
    );
};

export default Results; 