import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import styled from "styled-components";

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  height: calc(100vh - 20px);
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  background-color: white;
`;

const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  
  @media (max-width: 768px) {
    min-width: 800px;
  }
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  padding: 0;
  border-bottom: 1px solid #eee;
`;

const HeaderCover = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: white;
  z-index: 2;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 60px repeat(5, 1fr);
  margin: 0;
  padding: 5px 0;
  background-color: white;
  
  @media (max-width: 768px) {
    grid-template-columns: 60px repeat(5, 1fr);
  }
`;

const HeaderCell = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  color: black;
  white-space: nowrap;
  
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const VisualizationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 1100px;
  position: relative;
  margin-top: 30px;
  
  @media (max-width: 768px) {
    height: 900px;
  }
  
  @media (max-width: 480px) {
    height: 700px;
  }
`;

const ScaleAxis = styled.div`
  width: 60px;
  height: 100%;
  position: relative;
  flex-shrink: 0;
  z-index: 2;
  
  @media (max-width: 768px) {
    width: 40px;
  }
`;

const ScaleLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  width: 2px;
  background-color: #ddd;
`;

const ScaleMark = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 2;
`;

const ScaleLabel = styled.div`
  position: absolute;
  right: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  padding: 0 5px;
  transform: translateY(-50%);
  
  @media (max-width: 768px) {
    font-size: 12px;
    right: 30px;
  }
`;

const ScaleTick = styled.div`
  position: absolute;
  right: 30px;
  width: 10px;
  height: 2px;
  background-color: #ddd;
`;

const HorizontalGridLine = styled.div`
  position: absolute;
  left: 31px;
  right: 0;
  height: 1px;
  background-color: #eee;
  z-index: 1;
`;

const VerticalGridLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #ccc;
  z-index: 1;
`;

const CategoryColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  height: 100%;
  position: relative;
`;

const CategoryColumn = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const PersonPhoto = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
  margin: 0;
  border: 2px solid white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: transform 0.2s ease, z-index 0.2s ease;
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  
  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
  }
`;

const NameTag = styled.div`
  position: absolute;
  transform: translateY(-50%);
  padding: 0;
  font-size: 14px;
  z-index: 1;
  border: none;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  overflow: visible;
  
  &:hover {
    z-index: 10;
    
    ${PersonPhoto} {
      transform: scale(1.1);
    }
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  
  ${NameTag}:hover & {
    opacity: 1;
  }
`;

// Create a new component for the title area and sticky header together
const TopSection = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
`;

const TeamSimilarityMatrix = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);

  // Survey categories with emojis
  const categories = [
    { id: "time", label: "⏳ Time Wealth ⏳" },
    { id: "social", label: "🫶 Social Wealth 🫶" },
    { id: "mental", label: "🧠 Mental Wealth 🧠" },
    { id: "physical", label: "🏃‍♂️ Physical Wealth 🏃‍♂️" },
    { id: "financial", label: "🏦 Financial Wealth 🏦" },
  ];

  // Scale marks
  const scaleMarks = [0, 5, 10, 15, 20];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "responses"));
        
        const responseData = querySnapshot.docs.map((doc) => {
          const docData = doc.data();
          return {
            id: doc.id,
            firstName: docData.firstName || "",
            responses: docData.responses || {},
          };
        });
        
        setData(responseData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      setForceUpdate(prev => !prev);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return <div>Loading data...</div>;
  }

  // Calculate position as percentage from top (20 at top, 0 at bottom)
  const getPositionPercentage = (score) => {
    return ((20 - score) / 20) * 100;
  };

  // Process data for each category
  const getCategoryData = (categoryLabel) => {
    const peopleScores = [];
    
    data.forEach(person => {
      const categoryResponses = Object.keys(person.responses || {})
        .filter(key => key.startsWith(categoryLabel))
        .map(key => person.responses[key]);
      
      if (categoryResponses.length > 0) {
        // Sum all responses in this category
        const totalScore = categoryResponses.reduce((sum, val) => sum + val, 0);
        peopleScores.push({
          name: person.firstName,
          score: totalScore
        });
      }
    });
    
    return peopleScores;
  };

  // Inside the TeamSimilarityMatrix component, before the return statement
  // Add this photo mapping function
  const getPhotoPath = (name) => {
    // Convert name to lowercase and remove any spaces for the filename
    const fileName = name.toLowerCase().replace(/\s+/g, '');
    return `/photos/${fileName}.jpg`;
  };

  return (
    <Container>
      <MatrixContainer>
        <StickyHeader>
          <HeaderCover />
          <HeaderRow>
            <HeaderCell>Score</HeaderCell>
            {categories.map((category) => (
              <HeaderCell key={category.id}>{category.label}</HeaderCell>
            ))}
          </HeaderRow>
        </StickyHeader>
        
        <VisualizationContainer>
          {/* Rest of the grid lines (excluding the first one) */}
          {scaleMarks.slice(1).map((mark) => (
            <HorizontalGridLine 
              key={`grid-${mark}`} 
              style={{ top: `${getPositionPercentage(mark)}%` }}
            />
          ))}
          
          {/* Scale axis with marks */}
          <ScaleAxis>
            <ScaleLine />
            {scaleMarks.map((mark) => (
              <ScaleMark 
                key={mark} 
                style={{ top: `${getPositionPercentage(mark)}%` }}
              >
                <ScaleTick />
                <ScaleLabel>{mark}</ScaleLabel>
              </ScaleMark>
            ))}
          </ScaleAxis>
          
          {/* Category columns with vertical separators */}
          <CategoryColumns>
            {/* Vertical grid lines between columns */}
            {[1, 2, 3, 4].map((index) => (
              <VerticalGridLine 
                key={`vgrid-${index}`} 
                style={{ left: `${(index * 20)}%` }}
              />
            ))}
            
            {categories.map((category, index) => {
              const peopleScores = getCategoryData(category.label);
              
              // Group people by score to avoid overlaps
              const scoreGroups = {};
              peopleScores.forEach(person => {
                const score = person.score;
                if (!scoreGroups[score]) {
                  scoreGroups[score] = [];
                }
                scoreGroups[score].push(person.name);
              });
              
              return (
                <CategoryColumn key={category.id}>
                  {Object.entries(scoreGroups).map(([score, names]) => {
                    const scoreNum = Number(score);
                    const topPosition = getPositionPercentage(scoreNum);
                    
                    // Allow up to 6 photos per row
                    const maxPerRow = 6;
                    const rowCount = Math.ceil(names.length / maxPerRow);
                    
                    // Render each name with positioning that allows overlap
                    return names.map((name, nameIndex) => {
                      const rowIndex = Math.floor(nameIndex / maxPerRow);
                      const positionInRow = nameIndex % maxPerRow;
                      
                      const itemsInThisRow = (rowIndex === rowCount - 1 && names.length % maxPerRow !== 0) 
                        ? names.length % maxPerRow 
                        : maxPerRow;
                      
                      // Calculate horizontal positioning within the column
                      // Use percentage-based positioning relative to the column width
                      let leftPercentage;
                      
                      if (itemsInThisRow === 1) {
                        // Center single photo
                        leftPercentage = 50;
                      } else if (itemsInThisRow <= 3) {
                        // Distribute 2-3 photos evenly
                        const step = 80 / (itemsInThisRow + 1);
                        leftPercentage = 10 + (positionInRow + 1) * step;
                      } else {
                        // For 4-6 photos, allow overlap
                        // Calculate width of column and distribute photos with overlap
                        const totalWidth = 80; // Use 80% of column width
                        const photoWidth = 54; // Photo width in pixels
                        const columnWidthPercent = totalWidth / itemsInThisRow;
                        
                        // Calculate left position with overlap
                        leftPercentage = 10 + (positionInRow * columnWidthPercent) + (columnWidthPercent / 2);
                      }
                      
                      // Vertical offset with enough spacing between rows
                      const verticalOffset = rowIndex * 15;
                      
                      return (
                        <NameTag 
                          key={`${category.id}-${score}-${nameIndex}`} 
                          style={{ 
                            top: `${topPosition + verticalOffset}%`,
                            left: `${leftPercentage}%`,
                            transform: `translate(-50%, -50%)`, // Center the photo at the calculated position
                            width: `54px`,
                            height: `54px`
                          }}
                          title={`${name}: ${scoreNum}/20`}
                        >
                          <PersonPhoto 
                            src={getPhotoPath(name)} 
                            alt={name}
                            onError={(e) => {
                              e.target.style.display = 'none';
                              const span = document.createElement('span');
                              span.textContent = name;
                              e.target.parentNode.appendChild(span);
                            }} 
                          />
                          <Tooltip>Score: {scoreNum}/20</Tooltip>
                        </NameTag>
                      );
                    });
                  })}
                </CategoryColumn>
              );
            })}
          </CategoryColumns>
        </VisualizationContainer>
      </MatrixContainer>
      {window.innerWidth <= 768 && (
        <div style={{
          textAlign: 'center',
          padding: '10px',
          fontSize: '14px',
          color: '#666'
        }}>
          ← Scroll horizontally to view all data →
        </div>
      )}
    </Container>
  );
};

export default TeamSimilarityMatrix; 