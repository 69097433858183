import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyACvbCgd-gzj6Gq2gtaQSSImalTV7yYyGA",
  authDomain: "five-types-of-wealth-survey.firebaseapp.com",
  projectId: "five-types-of-wealth-survey",
  storageBucket: "five-types-of-wealth-survey.appspot.com",
  messagingSenderId: "377018415726",
  appId: "1:377018415726:web:5e14816011f1a8cc5a047f"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  'hd': 'stackpoint.com'  // This restricts to stackpoint.com domain
});
export const db = getFirestore(app); 