import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { GlobalStyles } from "./styles/GlobalStyles";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Survey from "./components/Survey";
import Results from "./components/Results";

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <GlobalStyles /> {/* ✅ Global styles applied everywhere */}
            <Router>
                <Navbar user={user} setUser={setUser} />
                <Routes>
                    <Route path="/" element={user ? <Navigate to="/survey" /> : <Home setUser={setUser} />} />
                    <Route path="/survey" element={user ? <Survey user={user} /> : <Navigate to="/" />} />
                    <Route path="/results" element={user ? <Results user={user} /> : <Navigate to="/" />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
