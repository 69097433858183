import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import styled from "styled-components";

// Styled Components
const TableWrapper = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    overflow-x: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 600px;
`;

const Th = styled.th`
    background: #f5f5f5;
    padding: 10px 8px;
    text-align: left;
    font-weight: 600;
    border: 1px solid #e0e0e0;
    color: #333;
    font-size: 14px;
    &:first-child {
        border-top-left-radius: 4px;
    }
    &:last-child {
        border-top-right-radius: 4px;
    }
`;

const Td = styled.td`
    padding: 8px;
    border: 1px solid #e0e0e0;
    text-align: center;
    background-color: ${({ $intensity }) => `rgba(34, 139, 34, ${$intensity})`};
    font-size: 14px;
    transition: background-color 0.2s ease;
    &:hover {
        filter: brightness(1.1);
    }
`;

const QuestionTd = styled.td`
    padding: 8px;
    border: 1px solid #e0e0e0;
    text-align: left;
    width: 45%;
    font-size: 14px;
    line-height: 1.2;
    color: #333;
`;

const SectionHeader = styled.tr`
    background: #f8f8f8;
    font-size: 16px;
    font-weight: 600;
`;

const SectionTd = styled.td`
    padding: 10px 8px;
    font-weight: 600;
    text-align: left;
    border: 1px solid #e0e0e0;
    background: #f8f8f8;
    font-size: 16px;
    color: #333;
`;

const PhotoGrid = styled.div`
    display: grid;
    gap: 8px;
    padding: 8px;
    background-color: #333;
    border-radius: 4px;
    grid-template-columns: ${({ count }) => {
        if (count <= 1) return '1fr';
        if (count === 2) return 'repeat(2, 1fr)';
        return 'repeat(3, 1fr)';
    }};
    justify-items: center;
`;

const PhotoContainer = styled.div`
    position: relative;
    width: ${({ count }) => {
        if (count <= 1) return '80px';
        if (count === 2) return '60px';
        return '100%';
    }};
    padding-bottom: ${({ count }) => {
        if (count <= 1) return '80px';
        if (count === 2) return '60px';
        return '100%';
    }};
    margin: 0 auto;
`;

const Photo = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
`;

const NameList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: ${({ count }) => {
        if (count <= 1) return '100px';
        if (count === 2) return '160px';
        return '180px';
    }};
`;

const TooltipDiv = styled.div`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000;
    font-size: 13px;
    border: 1px solid #e0e0e0;
    min-width: ${({ count }) => {
        if (count <= 1) return '100px';
        if (count === 2) return '160px';
        return '180px';
    }};
    text-align: left;
    margin-bottom: 8px;
    transition: opacity 0.2s ease;
`;

const HeatMapTable = () => {
    const [responses, setResponses] = useState([]);
    const [hoveredCell, setHoveredCell] = useState(null);

    const categories = {
        "⏳ Time Wealth ⏳": [
            "I am in control of my calendar and priorities",
            "I have a clear understanding of the 2-3 most important priorities in my personal and professional lives",
            "I am able to consistently direct attention and focus to the important priorities that I have identified",
            "I rarely feel too busy or scattered to spend time on the most important priorities",
            "I have a deep awareness of the finite, impermanent nature of my time and its importance as my most precious asset",
        ],
        "🫶 Social Wealth 🫶": [
            "I have a core set of deep, loving, supportive relationships",
            "I am consistently able to be the partner, parent, family member, and friend that I would want to have",
            "I have a network of loose relationships I can learn from and build on",
            "I have a deep feeling of connection to a community or to something bigger than myself",
            "I do not attempt to achieve status, respect, or admiration through material purchases",
        ],
        "🧠 Mental Wealth 🧠": [
            "I regularly embrace a childlike curiosity",
            "I have a clear purpose that provides daily meaning and aligns short and long-term decision making",
            "I pursue growth and consistently chase my full potential",
            "I have a fundamental belief that I am able to continuously change, develop, and adapt",
            "I have regular rituals that allow me to create space to think, reset, wrestle with questions, and recharge",
        ],
        "🏃‍♂️ Physical Wealth 🏃‍♂️": [
            "I feel strong, healthy, and vital for my age",
            "I move my body regularly through a structured routine and have an active lifestyle",
            "I eat primarily whole, unprocessed foods",
            "I sleep 7 or more hours per night on a regular basis and feel rested and recovered",
            "I have a clear plan in place to allow me to physically thrive into my later years",
        ],
        "🏦 Financial Wealth 🏦": [
            "I have a clear definition of what it means to have enough financially",
            "I have income that is steadily growing alongside my skills and expertise",
            "I manage my monthly expenses so that they are reliably below my income",
            "I have a clear process for investing excess monthly income for long-term compounding",
            "I use my financial wealth as a tool to build other types of wealth",
        ],
    };

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "responses"));
            const rawData = [];

            console.log("=== Starting data fetch ===");
            
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                console.log("Document data:", data); // Log each document
                if (data.responses) {
                    rawData.push({
                        responses: data.responses,
                        firstName: data.firstName
                    });
                }
            });

            console.log("Raw data collected:", rawData); // Log processed raw data

            const formattedData = {};
            Object.keys(categories).forEach((category) => {
                formattedData[category] = categories[category].map((question) => ({
                    question,
                    counts: [0, 0, 0, 0, 0],
                    usersByResponse: [[], [], [], [], []]
                }));
            });

            rawData.forEach((data) => {
                Object.entries(data.responses).forEach(([key, value]) => {
                    const [category, index] = key.split("-");
                    if (formattedData[category] && value >= 0 && value <= 4) {
                        formattedData[category][index].counts[value]++;
                        formattedData[category][index].usersByResponse[value].push(data.firstName);
                    }
                });
            });

            console.log("Formatted data:", formattedData); // Log final formatted data
            setResponses(formattedData);
        };

        fetchData();
    }, []);

    const getPhotoPath = (name) => {
        const fileName = name.toLowerCase().replace(/\s+/g, '');
        return `/photos/${fileName}.jpg`;
    };

    return (
        <TableWrapper>
            <StyledTable>
                <thead>
                    <tr>
                        <Th>Question</Th>
                        <Th>Strongly Disagree</Th>
                        <Th>Disagree</Th>
                        <Th>Neutral</Th>
                        <Th>Agree</Th>
                        <Th>Strongly Agree</Th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(responses).map(([category, questions]) => (
                        <React.Fragment key={category}>
                            <SectionHeader>
                                <SectionTd colSpan="6">{category}</SectionTd>
                            </SectionHeader>
                            {questions.map((q, qIndex) => (
                                <tr key={qIndex}>
                                    <QuestionTd>{q.question}</QuestionTd>
                                    {q.counts.map((count, rIndex) => (
                                        <Td 
                                            key={rIndex} 
                                            $intensity={count / 10}
                                            onMouseEnter={() => {
                                                console.log('Hovering cell:', `${category}-${qIndex}-${rIndex}`);
                                                setHoveredCell(`${category}-${qIndex}-${rIndex}`);
                                            }}
                                            onMouseLeave={() => setHoveredCell(null)}
                                            style={{ 
                                                position: 'relative',
                                                backgroundColor: hoveredCell === `${category}-${qIndex}-${rIndex}` ? '#f0f0f0' : undefined 
                                            }}
                                        >
                                            {count > 0 ? count : ""}
                                            {hoveredCell === `${category}-${qIndex}-${rIndex}` && count > 0 && (
                                                <TooltipDiv count={q.usersByResponse[rIndex].length}>
                                                    <NameList count={q.usersByResponse[rIndex].length}>
                                                        <PhotoGrid count={q.usersByResponse[rIndex].length}>
                                                            {q.usersByResponse[rIndex].map((name, i) => (
                                                                <PhotoContainer 
                                                                    key={i} 
                                                                    count={q.usersByResponse[rIndex].length}
                                                                >
                                                                    <Photo 
                                                                        src={getPhotoPath(name)} 
                                                                        alt={name}
                                                                        onError={(e) => {
                                                                            e.target.style.display = 'none';
                                                                            const span = document.createElement('span');
                                                                            span.textContent = name;
                                                                            e.target.parentNode.appendChild(span);
                                                                        }}
                                                                    />
                                                                </PhotoContainer>
                                                            ))}
                                                        </PhotoGrid>
                                                    </NameList>
                                                </TooltipDiv>
                                            )}
                                        </Td>
                                    ))}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </StyledTable>
        </TableWrapper>
    );
};

export default HeatMapTable; 