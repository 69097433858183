import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import styled from "styled-components";
import { Radar } from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip } from "chart.js";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

const Container = styled.div`
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: center;
`;

const ToggleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
`;

const ToggleButton = styled.button`
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: 1px solid ${({ $active, $color }) => ($active ? $color : "#c7ea47")};
    background-color: ${({ $active, $color }) => ($active ? $color : "white")};
    color: ${({ $active }) => ($active ? "white" : "#333")};
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${({ $color }) => $color || "#B3d134"};
        color: white;
    }
`;

const ChartContainer = styled.div`
    width: 600px;
    height: 600px;
    max-width: 100%;
    margin: auto;
`;

const RadarChart = () => {
    const [surveyData, setSurveyData] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [showAll, setShowAll] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            console.log("Starting to fetch data in RadarChart component");
            const querySnapshot = await getDocs(collection(db, "responses"));
            const data = querySnapshot.docs.map(doc => ({
                firstName: doc.data().firstName,
                responses: doc.data().responses,
            }));
            
            console.log("RadarChart - Raw data:", data);
            console.log("RadarChart - Number of responses:", data.length);
            
            setSurveyData(data);
            setSelectedMembers(data.map(d => d.firstName));
            console.log("RadarChart - Selected members:", data.map(d => d.firstName));
        };

        fetchData();
    }, []);

    const colors = {
        Adam: "#FF6384",
        Chris: "#36A2EB",
        Cristina: "#FFCE56",
        Rene: "#4BC0C0",
        Petr: "#9966FF",
        Evan: "#404040",
        Weber: "#76B947",
        Chaput: "#D95F02",
        Spyro: "#7570B3",
        Amily: "#E7298A",
        Matt: "#66A61E",
        Austin: "#E6AB02",
        James: "#A6761D",
    };

    const toggleMember = (name) => {
        if (selectedMembers.includes(name)) {
            setSelectedMembers(selectedMembers.filter(member => member !== name));
        } else {
            setSelectedMembers([...selectedMembers, name]);
        }
    };

    const toggleShowAll = () => {
        setSelectedMembers(showAll ? [] : surveyData.map(d => d.firstName));
        setShowAll(!showAll);
    };

    const categories = ["⏳ Time Wealth ⏳", "🫶 Social Wealth 🫶", "🧠 Mental Wealth 🧠", "🏃‍♂️ Physical Wealth 🏃‍♂️", "🏦 Financial Wealth 🏦"];
    
    const activeMembers = surveyData.filter(({ firstName }) => selectedMembers.includes(firstName));

    const chartData = {
        labels: categories.map(cat => cat.replace(/[^\w\s]/g, '').trim()),
        datasets: activeMembers.length > 0
            ? activeMembers.map(person => ({
                label: person.firstName,
                data: categories.map(category =>
                    Object.keys(person.responses)
                        .filter(key => key.startsWith(category))
                        .reduce((sum, key) => sum + person.responses[key], 0) 
                    ),
                backgroundColor: colors[person.firstName] + "33",
                borderColor: colors[person.firstName],
                pointBackgroundColor: colors[person.firstName],
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: colors[person.firstName],
            }))
            : [{
                label: "Empty Chart",
                data: [0, 0, 0, 0, 0],
                backgroundColor: "#dddddd33",
                borderColor: "#dddddd",
                pointBackgroundColor: "#dddddd",
                pointBorderColor: "#fff",
            }],
    };

    const options = {
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: 20,
                angleLines: { display: true },
                grid: { circular: true },
                pointLabels: {
                    font: { size: 14, weight: "bold" },
                    color: "#1b1f21",
                },
            },
        },
        plugins: {
            legend: { display: false },
        },
        responsive: true,
        maintainAspectRatio: false,
    };
    
    return (
        <Container>
            <ToggleButton $active={showAll} onClick={toggleShowAll}>
                {showAll ? "Deselect All" : "Show All"}
            </ToggleButton>
            <ToggleContainer>
                {surveyData.map(({ firstName }) => (
                    <ToggleButton
                        key={firstName}
                        $active={selectedMembers.includes(firstName)}
                        $color={colors[firstName]}
                        onClick={() => toggleMember(firstName)}
                    >
                        {firstName}
                    </ToggleButton>
                ))}
            </ToggleContainer>
            <ChartContainer>
                <Radar data={chartData} options={options} />
            </ChartContainer>
        </Container>
    );
};

export default RadarChart; 