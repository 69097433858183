import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import styled from "styled-components";
import confetti from "canvas-confetti";

// ✅ Define quizData at the top
const quizData = [
    {
        category: "⏳ Time Wealth ⏳",
        statements: [
            "I am in control of my calendar and priorities",
            "I have a clear understanding of the 2-3 most important priorities in my personal and professional lives",
            "I am able to consistently direct attention and focus to the important priorities that I have identified",
            "I rarely feel too busy or scattered to spend time on the most important priorities",
            "I have a deep awareness of the finite, impermanent nature of my time and its importance as my most precious asset",
        ],
    },
    {
        category: "🫶 Social Wealth 🫶",
        statements: [
            "I have a core set of deep, loving, supportive relationships",
            "I am consistently able to be the partner, parent, family member, and friend that I would want to have",
            "I have a network of loose relationships I can learn from and build on",
            "I have a deep feeling of connection to a community or to something bigger than myself",
            "I do not attempt to achieve status, respect, or admiration through material purchases",
        ],
    },
    {
        category: "🧠 Mental Wealth 🧠",
        statements: [
            "I regularly embrace a childlike curiosity",
            "I have a clear purpose that provides daily meaning and aligns short and long-term decision making",
            "I pursue growth and consistently chase my full potential",
            "I have a fundamental belief that I am able to continuously change, develop, and adapt",
            "I have regular rituals that allow me to create space to think, reset, wrestle with questions, and recharge",
        ],
    },
    {
        category: "🏃‍♂️ Physical Wealth 🏃‍♂️",
        statements: [
            "I feel strong, healthy, and vital for my age",
            "I move my body regularly through a structured routine and have an active lifestyle",
            "I eat primarily whole, unprocessed foods",
            "I sleep 7 or more hours per night on a regular basis and feel rested and recovered",
            "I have a clear plan in place to allow me to physically thrive into my later years",
        ],
    },
    {
        category: "🏦 Financial Wealth 🏦",
        statements: [
            "I have a clear definition of what it means to have enough financially",
            "I have income that is steadily growing alongside my skills and expertise",
            "I manage my monthly expenses so that they are reliably below my income",
            "I have a clear process for investing excess monthly income for long-term compounding",
            "I use my financial wealth as a tool to build other types of wealth",
        ],
    },
];

// ✅ Styled components for layout
const Container = styled.div`
    max-width: 100%;
    margin: auto;
    padding: 20px;
    text-align: center;

    @media (max-width: 768px) {
        padding: 15px;
        margin: 0 10px;
        max-width: 100%;
    }
`;

const QuestionText = styled.p`
    font-size: 20px;
    font-weight: 600;
    color: #1b1f21;
    margin-bottom: 15px;
    margin-top: 20px;
    line-height: 1.4;
    text-align: center;

    @media (max-width: 480px) {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: left;
    }
`;

const OptionsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
    padding: 0 10px;

    @media (min-width: 769px) {
        flex-wrap: nowrap;
        justify-content: center;
    }

    @media (max-width: 768px) {
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;
    }
`;

const Label = styled.label`
    font-size: 16px;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    transition: background-color 0.2s;
    white-space: nowrap;

    @media (min-width: 769px) {
        justify-content: center;
        padding: 5px 10px;
        background-color: transparent;
        min-width: auto;

        &:hover {
            background-color: transparent;
        }
    }

    @media (max-width: 768px) {
        flex: 1;
        justify-content: center;
        min-width: 140px;
        background-color: #f8f8f8;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    @media (max-width: 480px) {
        font-size: 14px;
        justify-content: flex-start;
        padding: 10px;
        margin: 0;
        width: 100%;
        min-width: unset;
    }
`;

const CategoryTitle = styled.h2`
    font-size: 28px;
    margin-bottom: 30px;
    color: #1b1f21;
    line-height: 1.3;

    @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 25px;
    }

    @media (max-width: 480px) {
        font-size: 20px;
        margin-bottom: 20px;
        padding: 0 10px;
    }
`;

const ProgressIndicator = styled.div`
    margin-bottom: 20px;
    font-size: 16px;
    color: #666;

    @media (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 15px;
    }
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 8px;
    background-color: #eee;
    border-radius: 4px;
    margin-top: 8px;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        height: 100%;
        width: ${props => (props.$progress * 100)}%;
        background-color: #c7ea47;
        transition: width 0.3s ease;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;

    @media (max-width: 480px) {
        gap: 15px;
        flex-wrap: wrap;
    }
`;

const StyledButton = styled.button`
    width: 160px;
    padding: 14px;
    font-size: 16px;
    text-align: center;
    border: none;
    background-color: #c7ea47;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;

    &:hover {
        background-color: #B3D134;
        transform: scale(1.02);
    }

    &:disabled {
        background-color: #ddd;
        color: #999;
        cursor: not-allowed;
        transform: none;
    }

    @media (max-width: 480px) {
        width: 100%;
        padding: 12px;
    }
`;

const CelebrationGif = styled.img`
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-top: 20px;
    border-radius: 12px;
`;

const Survey = ({ user }) => {
    const [responses, setResponses] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!user) {
            console.warn("User is not logged in.");
        }
    }, [user]);

    const handleResponse = (category, statementIndex, value) => {
        setResponses({
            ...responses,
            [`${category}-${statementIndex}`]: value,
        });
    };

    const handleNext = () => {
        if (currentQuestion < quizData.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleSubmit = async () => {
        if (!user) {
            alert("You must be logged in to submit the survey.");
            return;
        }

        try {
            // Extract firstName from email (everything before the @ symbol)
            const firstName = user.email.split('@')[0];
            // Capitalize first letter
            const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

            await addDoc(collection(db, "responses"), {
                userId: user.uid,
                email: user.email,
                firstName: formattedFirstName,
                responses,
                timestamp: new Date(),
            });

            // 🎉 Confetti Effect
            confetti({
                particleCount: 100,
                spread: 160,
                scalar: 1.2,
                origin: { y: 0.6 },
                colors: ["#c7ea47", "#ffcc00"],
            });

            setSubmitted(true);
        } catch (error) {
            console.error("Error saving response:", error);
            alert("Failed to save your response. Please try again.");
        }
    };

    if (submitted) {
        return (
            <Container>
                <h2>🏆 All done! 🏆</h2>
                <p>Thank you for completing the survey! We'll dig into results and insights in our onsite.</p>
                <CelebrationGif 
                    src="https://media.giphy.com/media/i21tixUQEE7TEqwmYa/giphy.gif" 
                    alt="Celebration GIF" 
                />
            </Container>
        );
    }

    const currentCategory = quizData[currentQuestion];

    return (
        <Container>
            <ProgressIndicator>
                Question Set {currentQuestion + 1} of {quizData.length}
                <ProgressBar $progress={(currentQuestion + 1) / quizData.length} />
            </ProgressIndicator>
            <CategoryTitle>{currentCategory.category}</CategoryTitle>
            {currentCategory.statements.map((statement, index) => (
                <div key={index}>
                    <QuestionText>{statement}</QuestionText>
                    <OptionsContainer>
                        {[0, 1, 2, 3, 4].map((value) => (
                            <Label key={value}>
                                <input
                                    type="radio"
                                    name={`${currentCategory.category}-${index}`} 
                                    value={value}
                                    checked={responses[`${currentCategory.category}-${index}`] === value}
                                    onChange={() => handleResponse(currentCategory.category, index, value)}
                                />
                                {["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"][value]}
                            </Label>
                        ))}
                    </OptionsContainer>
                </div>
            ))}
            <ButtonContainer>
                <StyledButton onClick={handlePrevious} disabled={currentQuestion === 0}>Previous</StyledButton>
                <StyledButton
                    onClick={currentQuestion === quizData.length - 1 ? handleSubmit : handleNext}
                    disabled={!quizData[currentQuestion].statements.every((_, index) => responses.hasOwnProperty(`${quizData[currentQuestion].category}-${index}`))}
                >
                    {currentQuestion === quizData.length - 1 ? "Submit" : "Next"}
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default Survey; 