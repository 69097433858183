import React from "react";
import { auth, googleProvider } from "../firebaseConfig";
import { signInWithPopup, signOut } from "firebase/auth";
import styled from "styled-components";

const Container = styled.div`
    text-align: center;
    margin-top: 50px;
    max-width: 600px; /* ✅ Ensures content is not too wide */
    margin-left: auto;
    margin-right: auto;
    padding: 20px; /* ✅ Adds padding to match survey page */

    @media (max-width: 768px) {
        padding: 15px; /* ✅ Slightly smaller padding on mobile */
    }
`;

const StyledImage = styled.img`
    width: 100%;
    max-width: 600px; /* ✅ Keeps the GIF at a reasonable size */
    height: auto;
    margin-bottom: 20px; /* ✅ Adds spacing below GIF */
`;

const Button = styled.button`
    background: #c7ea47;
    color: #1b1f21;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 20px;
    margin-top: 20px;
`;

const Home = ({ setUser }) => {
    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const email = result.user.email;
            if (!email.endsWith('@stackpoint.com')) {
                await signOut(auth);
                alert('Please sign in with your stackpoint.com email address.');
                return;
            }
            setUser(result.user);
        } catch (error) {
            console.error("Login error:", error);
            alert("Failed to sign in. Please try again.");
        }
    };

    return (
        <Container>
            {/* ✅ Online GIF Example */}
            <StyledImage src="https://media.giphy.com/media/3ornk57KwDXf81rjWM/giphy.gif?cid=790b7611r461opxcwqxx6kv4e950gzomxo4zqvykq6x7fv1n&ep=v1_gifs_search&rid=giphy.gif&ct=g" alt="Welcome GIF" />
            <h2>Welcome to the Onsite Survey!</h2>
            <p>This is the survey from "The Five Types of Wealth." We won't share anyone's responses outside of our onsite. Our goal is simply to get to know each other better, develop a shared framework and vocabulary for where we are in life and where we hope to be in the future, and surface ways we can help each other along the way.</p>
            <Button onClick={handleGoogleLogin}>Sign in with your Stackpoint Google Account</Button>
        </Container>
    );
};

export default Home; 