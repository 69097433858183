import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyles = createGlobalStyle`
    * {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        background-color: #f8f8f2;
        color: #1b1f21;
        line-height: 1.6;
    }

    button {
        font-family: Arial, sans-serif;
        font-size: 16px;
        cursor: pointer;
        border: none;
        background-color: #c7ea47;
        color: black;
        border-radius: 20px;
        transition: background-color 0.2s ease-in-out;
        padding: 10px 15px;
        
        &:hover {
            background-color: #b3d134;
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    /* Custom Styling for Radio Buttons */
    input[type="radio"] {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #666;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        cursor: pointer;
        outline: none;
        background-color: white;
        transition: all 0.2s ease-in-out;
    }

    input[type="radio"]:checked {
        background-color: #C7EA47;
        border-color: #A4C437;
    }

    input[type="radio"]::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #c7ea47;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    input[type="radio"]:checked::after {
        opacity: 1;
    }

    /* Styling for Page Titles */
    h1, h2, h3 {
        font-family: Arial, sans-serif;
        font-weight: bold;
        text-align: center;
    }

    /* Table Styling (Results Page) */
    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    th, td {
        padding: 10px;
        border: 1px solid #ddd;
        text-align: center;
    }

    th {
        background-color: #1b1f21;
        color: white;
    }

    td {
        background-color: #ffffff;
        color: black;
    }
`; 