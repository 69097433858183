import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { auth } from "../firebaseConfig";
import { signOut } from "firebase/auth";

const Nav = styled.nav`
    background: white;
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    position: sticky;
    top: 0;
    z-index: 1000;
`;

const NavContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NavLinks = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

const NavLink = styled(Link)`
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f5f5f5;
    }
`;

const LogoutButton = styled.button`
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f5f5f5;
    }
`;

const Navbar = ({ user, setUser }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate("/");
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    return (
        <Nav>
            <NavContainer>
                <NavLinks>
                    {user && (
                        <>
                            <NavLink to="/survey">Survey</NavLink>
                            <NavLink to="/results">Results</NavLink>
                        </>
                    )}
                </NavLinks>
                <NavLinks>
                    {user ? (
                        <>
                            <span style={{ color: '#666' }}>{user.email}</span>
                            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                        </>
                    ) : (
                        <NavLink to="/">Login</NavLink>
                    )}
                </NavLinks>
            </NavContainer>
        </Nav>
    );
};

export default Navbar; 